<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
    <div class='transition' :style='getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 12pt; font-weight: bold;width: 100%;'>Import BookingFile 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 9pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		        <div v-if="false">
		        <span class='SVcontrolLabel'>Channel/MEDIA:</span><br/>
			
			    <GSelectSimple :options="stations" style='width:100%' v-model="stationId" @changed='stationChanged'/>
			    
			    <div v-if="data && data.media" 
			         @click="openScheduleView( data.media.id, new Date(dateFrom).toISOString().split('T')[0])">
			         <center>
			        
			    	<GImage :data="getImgData( data.media.logo)"/>
			      </center>
			    </div>
			    <span class='SVcontrolLabel'>From date:</span><br/><InputDatePick  @change="dateChanged" :auto="false" v-model="dateFrom"/>
			    <span class='SVcontrolLabel'>To date:</span><br/><InputDatePick  @change="dateChanged" :auto="false" v-model="dateTo"/>
				<br/>
				</div>
			</div>	
	</div>
	
    <div class='transition uploadArea' style='float: top; position: relative;' id='uploader' 
		 		@drop="dropFile($event, 0, 0, 'uploader');"
			    @dragenter.prevent="hoverLine( 'uploader')"
			    @dragleave.prevent="leaveLine( 'uploader')"		        
				@dragover.prevent="hoverLineMain( 'uploader')" >
				<div v-if="data && data.from && data.to">
					{{data.from}} - {{data.to}}
				</div>
				<div v-if="data && data.channels && data.channels.length">
					<div>Channels:</div>
					<div v-for="(ch, chi) in data.channels" :key="'ch'+chi">- {{ch.name}}</div>
					<br/>
					<button class="button myButton" :style="getAppStyle()" @click="importRatings()"><mdicon name="import"/> Import ratings</button>
					
					<div v-if="data.header && data.header.id">
					Imported with header: <span style='cursor: pointer;' @click="$refs.editor.open('ForecastHeader', data.header.id)">{{data.header.name}}</span>					
					</div>	
				</div>	
				<div v-else style='padding-top: 50pt; display: block; text-align: center; vertical-align: middle; align-items: center; justify-content: center; height: 100%; width: 100; border: 2pt solid #fcd;'>
			      <span style='color: #aaa;'>drop file here (forecast or actual ratings)</span>
			    </div>
				
		 
 	</div>
 	
	<ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem v-if="contextData.header" @clicked="$refs.menu.close(); $refs.editor.open('GridHeader', contextData.header.id)" >
          Open {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.detail" @clicked="$refs.menu.close(); $refs.editor.open('GridDetail', contextData.detail.id)" >
          Open {{contextData.detail.name}}
        </ContextMenuItem>
        <ContextMenuSep v-if="!contextData.detail"/>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); activate( contextData.header)">
          Activate {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); compare( contextData.header)">
          Compare
        </ContextMenuItem>
       </template>
    </ContextMenu>
	<PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
	<GConfirm ref='confirm'/>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
 </div>
</template>	
<script>
import {HTTP, HTTPMP, gridAPI, reportAPI, opsAPI, bookingFileAPI, bngAPI, fwAPI, userAPI, invAPI, setReload, showError, myLocale, formatNumber} from '@/variables.js';
import {store, restore} from '@/ratingsImport.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import { setGoBack } from '@/breadCrumb.js';
import {printTimeOpt, printTimeHHMM, printTime } from '@/basicTimeFN.js';
import ProgressBar from '@/components/ProgressBar';
import Switch from '@/components/Switch';
import GImage from '@/components/GImage';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuItem from '@/components/ContextMenuItem';
import RunDownAsRun from '@/components/asrun/RunDownAsRun';
import InputWeekdays from '@/components/inputElements/DisplayWeekdays';
import RunDown from '@/components/asrun/RunDown';
import GSelectMSimple from '@/components/GSelectMSimple';
import GSelectSimple from '@/components/GSelectSimple';
import GSelect from '@/components/misc/GSelect';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import InputDatePickM from '@/components/inputElements/InputDatePickM'; 
import GridBreakView from '@/components/GridBreakView';
import GConfirm from '@/components/GConfirm';
import PDFViewer from '@/components/PDFViewer';
import JQuery from "jquery";
import 'w3-css/w3.css';
var momentTZ = require('moment-timezone')
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW_IMPORT_SCHEDULE',
   
  components : {
   //vSelect, 'app-switch': Switch, InputDatePick, GConfirm, GSelectSimple, RunDownAsRun, RunDown
   // 'app-switch': Switch, 
   //GSelect, 
   GConfirm, 
   GImage, 
   ProgressBar, 
   InputDatePick,  
   PDFViewer, 
   //InputDatePickM,  
   ContextMenu, ContextMenuItem, ContextMenuSep,
   //InputWeekdays,
   //GSelectMSimple,
   GSelectSimple
  },
  data () {
    return {
      stationId: 0,
      controlsRight: false,
      station: {},
      stations: [],
	  reports: [],
	  user: {},
      data: {},
	  reportData: {},
      dataStored: {},
      fileId: 0,
	  fileName: "",
      formData: {},
      dateFrom: null,
      dateTo: null,
      
      // ProgBar
      pbFromGridId: 0,
      pbToGridId: 0,
      pbHeaderId: 0,
      pbAction: "",
      pbData: {},
      pbTitle: "",
      showProgressBar: false,
      getBGStyle2, formatNumber,getAppStyle
      }
  },
  methods: {
	  getControlStyle() {
	    	if ( this.controlsRight )
	    	{
	    		return "float: right; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    	}
	    	return "float: left; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    },
	    getImgData(val) { return 'data:image/png;base64,' + val; },
	    openScheduleView(stationId, isoDay)
	    {
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	this.$router.replace('scheduleView?stationId='+stationId+'&day='+ isoDay);
	    },
	    setDates(d)
	    {
	    
	    	this.dates =d;
	    },
	    
    dropFile($event, line, rowIdx, lineREF)
    {
      let that = this;
      event.preventDefault();
      event.stopPropagation();
     
      this.showDropzone = false;
      const files = event.dataTransfer.files;
      const itemArray = [...files];
      
      const item = itemArray.find((i) => true);
      this.$refs.confirm.confirm( {text: 'Load ratings-file: ' + item.name, title: "Upload Grid", button1: "Cancel", button2: "Ok"}).then(x => {
    	  this.startLoader();
    	  let check =  new Promise(function (resolve, reject) {
    		  const formData = new FormData();
	       	  
	       	  that.leaveLine(lineREF);
		      formData.append('file', item);      
		      if (item) that.upload(formData, line, rowIdx, item.name);
		      that.stopLoader();

		   });
      });
    },
    fmtTimePart( aTime) {
	   	if ( aTime < 10 ) 
	   	{
	   		return "0"+aTime;
	   	}
	   	return ""+aTime;
	},
    printTime(time)
    {
      //console.log( "scanTime("+val+") " + this.time )
      //alert("scanTime("+JSON.stringify(val)+") " + this.time)
      if ( isNaN( time ) || ! time  )
      {
      	time = 0;
      }
    	let ss = time % 60;
      let mm = ((time - ss) / 60 ) % 60;
      let hh = ((time - ss - mm * 60) / 3600 ) % 60;
      let hhVal = this.fmtTimePart(hh);
      let mmVal = this.fmtTimePart(mm);
      //let ssVal = this.fmtTimePart(ss);
      return hhVal +":"+mmVal;
     },

    async upload(data, line, rowIdx, name) {
      let uploadFile = await this.uploadFile(data, line, rowIdx, name);
    },

    reload()
    {
    	this.compare(this.selectedHeader);
    },
    importRatings()
	{
		this.pbAction = "importRatingsInternal";
    	this.pbTitle = "Import data "+ this.data.file.name;
  	    this.showProgressBar = true;
	},
	importRatingsInternal(  myId, api)
	{
		let that=this;

	    api.post( gridAPI+"/loadRatingsFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+that.fileId+"/"+myId)
	        .then( response => 
	        { 
				that.data = response.data;
				
				that.dataStored = that.data;
				that.dateFrom = new Date( that.data.from);
				that.dateTo = new Date( that.data.to);
				that.fileId = that.data.file.id;
				
	            that.showProgressBar = false;
	            that.$toast.success("File '"+name+"' imported'", 'Ok', { timeout: 1500, position: "topRight" });
				this.showBreak( that.data.programLines);
	            that.$forceUpdate();
	      }).catch((err) => {showError( that.$toast, "File '"+name+"' not imported", err); that.showProgressBar = false;})
	},
	uploadFile(formData, line, rowIdx, name) 
	{
		this.fileName = name;
		this.formData = formData;
		this.pbAction = "uploadFileInternal";
    	this.pbTitle = "Import "+ this.fileName;
  	    this.showProgressBar = true;
	},
		
    uploadFileInternal(myId, api) 
    {
      let that=this;

      HTTPMP.put( bookingFileAPI+"/uploadFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myId, this.formData)
        .then( response => 
        { 
			that.data = response.data;
			that.showProgressBar = false;
			
            that.stopLoader();
            that.$toast.success("File '"+this.fileName+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "File '"+this.fileName+"' not uploaded", err); that.showProgressBar = false; that.stopLoader();})

    },
    action( what, myId, api)
    {
    	console.log(what+"/"+ myId+"/"+api)
   		this[what](myId, api)
    },
    
    hoverLine(line)
    {
    	$('#'+line).addClass('reqLineBG2');
    },
    hoverLineMain(line)
    {
    	//$('#'+line).addClass('reqLineBG');
    },
    leaveLine(line)
    {
    	$('#'+line).removeClass('reqLineBG2');
    },
	runReport( report) 
	{
		this.pbAction = "runReportInternal";
    	this.pbTitle = "Run "+ report.info;
    	this.pbReport=report;
  	    this.showProgressBar = true;
	},
	// record SpecialReportRequest ( String from, String to, Long mediaId, String classname) {} 
    runReportInternal(  myId, api) {
        let that = this;
		let from = momentTZ(this.dateFrom).utcOffset(0, true).format().split('T')[0];
		let to = momentTZ(this.dateTo).utcOffset(0, true).format().split('T')[0];
        let req = { from: from, to: to, mediaId: this.stationId, classname: this.pbReport.classname};
		//alert( JSON.stringify( req));
    	let dataLoad = new Promise(function (resolve, reject) {
            api.post( reportAPI+"/runSpecialReport/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                 	
                    that.reportData = response.data;
					//alert( JSON.stringify( that.reportData))
					that.showProgressBar = false;
                    resolve (true);                    
                 }).catch(e => {
					    that.showProgressBar = false;
                        that.$toast.error("loading data for report: " + e.response.data, 'Error', { position: "topRight" });
                        reject();
                    });
	      });
    },
	downloadUnzip( data)
    {
		
    	this.$refs.pdfView.download(data.data, data.name);
    },
    loadStation( stationId, activeDate )
    {
    	let that = this;
    	let userLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                	 that.user = response.data;
                	 that.stationIds = that.user.media;
                	 
                	 resolve (that.stationIds);
                 }).catch(e => {
                     that.$toast.error("loading user (ImportGrid): " + e.response.data, 'Error', { position: "topRight" });
                     that.stopLoader();
                     reject ();
                 });
	      });
    	userLoad.then( stationIds => {
        	let stationLoad = new Promise(function (resolve, reject) {
        	    //alert(sessionStorage.unitId);
        	    
                console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0");
				HTTP.post( reportAPI+"/getSpecialReports/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	        		 .then( response => 
	                 {
	                 	var allStations = response.data.stations;
						allStations.sort((a,b)=>a.name.localeCompare(b.name));
						that.stations = allStations.map(a => {return {id: a.id, label: a.name, visible: true}});
	                    that.reports = response.data.reports;
						console.log( JSON.stringify( that.reports))
	                    that.selectedStation =  that.stations[0];
	                    resolve (that.stations);                    
	                 }).catch(e => {
	                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
	                        reject ();
	                    });
    	      });
        	
        });
    },
    headerRClicked(event, header, detail)
    {
    	//alert( JSON.stringify({header: header, detail: detail}));
        event.preventDefault();
        this.$refs.menu.open(event, {header: header, detail: detail} )
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	storeSettings() 
	{
		try
		{
	    	let obj = this.$data;
	    	//this.userUpdated();
	    	store( obj);
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	},
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  beforeDestroy() {
    this.storeSettings();
  },
  created() {
	if ( sessionStorage.lastMediaId )
	{
		this.stationId = sessionStorage.lastMediaId;
	}
	
	let restored = restore();
	if ( restored && restore.data )
	{
		this.data = restored.data;
		this.dataStored = restored.dataStored;
		this.gridImported = restored.gridImported;
	}

	this.dateFrom = new Date().toISOString().split('T')[0]
	this.dateTo = new Date().toISOString().split('T')[0]
	this.loadStation();

	initAppMode();
  },
  watch: {
   stationId: function() {
	   sessionStorage.lastMediaId = this.stationId;
   },
   selectedStates: function() {
	    
		
	},
  },
  updated() {
  		//console.log("update...");

 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}
.todrag {
 display: block;
 width: 100%; 
 height: 16pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  width: 100%;
  display: block;
}
.progTitle {
  white-space: normal;
  width: 100%;
  display: flex;
  border: 1pt solid grey;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
  
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 72%;  
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.reqLineBG2 {
    cursor: pointer;
    color: #fff;
	background-color: #888 !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
.header {
  font-size: 14pt;
  font-weight: bold;
}

</style> 